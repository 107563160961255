import { sendPageInfo, sendUserAction } from '@postidigital/posti-google-analytics'
import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import styled from 'styled-components'

import { MainUserRadioGroup, NextButton, SelectRoles, SubTitle } from '../../components'
import { StoreContext } from '../../store'
import { IUserDetailOrganisation } from '../../store/dataModels/interfaces'
import { ContentSection } from '../../style/layout'
import i18n from '../../utils/i18n'

interface IProp {
  organisationData: IUserDetailOrganisation
}

const EditStep1Component: React.FC<IProp> = ({
  organisationData: {
    organization,
    organizationUser: { selectedRoles },
  },
}) => {
  const { userDetailsStore } = useContext(StoreContext)
  const [t] = useTranslation()
  const { orgid, userid } = useParams()

  const { pendingRequest } = userDetailsStore

  useEffect(() => {
    sendPageInfo({
      language: i18n.getLocale,
      solution: 'oneaccount-orgadmin',
      domain: window.location.hostname,
      pagePath: `/org-admin/edit-user/${userid}/${orgid}/1`,
      pageName: 'oneaccount-orgadmin-edit-users',
    })
    sendUserAction({
      actionType: 'editUser',
      businessId: orgid,
      userType: selectedRoles.includes('MainUser') ? 'main user' : 'user',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleNextClick = async () => {
    await userDetailsStore.getCustomerNumbersAndServiceContracts(organization.businessId, selectedRoles)
    userDetailsStore.setStep(2)
  }

  return (
    <ContentSection>
      <SubTitle as="h3">{t(`invite.userType`)}</SubTitle>
      <MainUserRadioGroup
        isMainUser={selectedRoles.includes('MainUser')}
        toggleUserRole={() => userDetailsStore.toggleUserRole(organization.businessId, 'MainUser')}
      />

      <SubTitle as="h3">{t(`invite.accessRights`)}</SubTitle>

      <SelectRoles
        roleGroups={organization.roleGroups}
        selectedRoleValues={selectedRoles}
        toggleUserRole={(selectedRoles) => userDetailsStore.toggleUserRole(organization.businessId, selectedRoles)}
        filterInvalidRoles={true}
        hasCustomerNumbers={organization.customerNumbers.length > 0}
        hasLCSNumbers={organization.logisticsContractNumbers.length > 0}
        hasTransportIds={!!organization.transportIds?.length}
        infoTranslationID="general.roleRequirementInfo"
      />

      <StyledButtonsWrapper>
        <NextButton loading={!!pendingRequest} onClick={handleNextClick} />
      </StyledButtonsWrapper>
    </ContentSection>
  )
}

export const EditStep1 = observer(EditStep1Component)

const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: auto;
`
