import { Body, Headline, Notification } from '@postidigital/posti-components/build/brand'
import { sendPageInfo } from '@postidigital/posti-google-analytics'
import { BrandTheme } from '@postidigital/posti-theme'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import styled, { css } from 'styled-components'

import { EmailsSection, MainTitle, NextButton, SelectOrganisationList, Stepper, SubTitle } from '../components'
import { StoreContext } from '../store'
import { ContentSection } from '../style/layout'

const InviteViewComponent: React.FC = () => {
  const { t, i18n } = useTranslation()
  const { inviteStore } = useContext(StoreContext)
  const navigate = useNavigate()

  const handleNext = useCallback(() => {
    navigate('/invite/2')
  }, [navigate])

  useEffect(() => {
    sendPageInfo({
      language: i18n.language,
      solution: 'oneaccount-orgadmin',
      domain: window.location.hostname,
      pagePath: `/org-admin/invite/1`,
      pageName: 'oneaccount-orgadmin-invite',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { emailList, selectedBusiness } = inviteStore

  return (
    <ContentSection gap="lg">
      <Stepper />
      <MainTitle>{t(`invite.inviteUser`)}</MainTitle>
      <ContentSection>
        <SubTitle>{t(`invite.selectOrg`)}</SubTitle>
        <SelectOrganisationList />
      </ContentSection>
      <ContentSection>
        <SubTitle>{t(`invite.addEmail`)}</SubTitle>
        <EmailsSection />
        <StyledNextButtonWrapper>
          <Notification dismissible={false} contentType="informational" style={{ maxWidth: '500px' }}>
            <ContentSection>
              <Headline as="div" size="Seven">
                {t(`invite.invPeriod`)}
              </Headline>
              <Body size="Five">{t(`invite.invInfo`)}</Body>
            </ContentSection>
          </Notification>

          <NextButton disabled={emailList.length < 1 || !selectedBusiness.businessId} onClick={handleNext} />
        </StyledNextButtonWrapper>
      </ContentSection>
    </ContentSection>
  )
}

export const InviteView = observer(InviteViewComponent)

const StyledNextButtonWrapper = styled('div')(
  ({
    theme: {
      brand: { spacing },
    },
  }) => css`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: ${spacing.space4}rem;

    @media (min-width: ${BrandTheme.breakpoint.lg}) {
      flex-wrap: nowrap;
      gap: ${spacing.space8}rem;
    }
  `
)
