import { Loading } from '@postidigital/posti-components/'
import { DownloadIcon, LinkButton } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Filters, InviteList, LandingUsers, MainTitle, SearchAndFilter, SubTitle } from '../components'
import { StoreContext } from '../store'
import { ContentSection } from '../style/layout'
import { breakpoint } from '../utils/breakpoint'

const MainViewComponent: React.FC = () => {
  const [t] = useTranslation()
  const { environmentStore, searchStore, layoutStore } = useContext(StoreContext)
  const { invites, isSearching, isFetchingCsv, fetchCsv } = searchStore

  return (
    <StyledWrapper>
      <ContentSection gap="lg">
        <ContentSection>
          <MainTitle>{t(`landing.userManagement`)}</MainTitle>
          <StyledDivider />
          <SearchAndFilter />
        </ContentSection>
        <ContentSection>
          <SubTitle>{t(`landing.invitations`)}</SubTitle>

          {isSearching ? (
            <StyledCenter>
              <Loading isBrand preset="postiOrange60" size="md" />
            </StyledCenter>
          ) : (
            <InviteList invites={invites} />
          )}
        </ContentSection>
        <ContentSection>
          <UsersHeadlineContainer>
            <SubTitle>{t(`general.users`)}</SubTitle>
            {environmentStore.hasFeature('csvDownload') && !isSearching && (
              <LinkButton
                id="download_csv_button"
                disabled={isFetchingCsv}
                onClick={fetchCsv}
                style={{ fontSize: '18px' }}
                icon={isFetchingCsv ? Loading : DownloadIcon}
              >
                {t(`landing.download`)}
              </LinkButton>
            )}
          </UsersHeadlineContainer>
          {isSearching ? (
            <StyledCenter>
              <Loading isBrand preset="postiOrange60" size="md" />
            </StyledCenter>
          ) : (
            <LandingUsers />
          )}
        </ContentSection>
      </ContentSection>
      {layoutStore.isDesktop && <Filters />}
    </StyledWrapper>
  )
}

export const MainView = observer(MainViewComponent)

const StyledCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledDivider = styled.div`
  display: none;

  @media ${breakpoint.mobile} {
    display: block;
    height: 2px;
    background: #e6ebf1;
    width: 100%;
    margin-bottom: 16px;
  }
`

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;

  & > div:first-child {
    width: 100%;
  }

  h2 {
    margin: 0;
  }
`

const UsersHeadlineContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: end;

  button {
    padding: 0;
    min-height: 0;
  }
`
