import { Loading } from '@postidigital/posti-components'
import { ErrorAnimation, SuccessAnimation } from '@postidigital/posti-components/build/animations'
import { observer } from 'mobx-react-lite'
import React from 'react'

interface IProps {
  pendingRequest: string
}

const FetchingAnimationComponent: React.FC<IProps> = ({ pendingRequest }) => {
  switch (pendingRequest) {
    case 'loading':
      return <Loading isBrand preset="postiOrange60" size="xl" />
    case 'success':
      return <SuccessAnimation width="111px" height="111px" />
    case 'error':
      return <ErrorAnimation width="111px" height="111px" />
  }
}

export const FetchingAnimation = observer(FetchingAnimationComponent)
